<div class="summary-section">
  <div class="summary-section-bg">
    <div class="container">
      <h3>
        Negative Impacts
      </h3>

      <div class="flex-row">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Score History</h5>
            <table class="table table-striped">
              <thead>
              <tr>
                <th>TYPE</th>
                <th>NUMBER</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Missed Payments</td>
                <td>{{(negativeMarks$ | async)?.missed_payments}}</td>
              </tr>
              <tr>
                <td>Judgements</td>
                <td>{{(negativeMarks$ | async)?.judgements}}</td>
              </tr>
              <tr>
                <td>Insolvencies</td>
                <td>{{(negativeMarks$ | async)?.insolvencies}}</td>
              </tr>
              </tbody>
            </table>
            <div class="text-center">
              <a [routerLink]="['/negative-marks']">View Details</a>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">How Do Negative Impacts Affect You?</h5>
            <p class="card-text">
              While negative impacts affect your score, it does not mean that you personally are "not in good standing" or that you cannot obtain new credit.
              There are many options available to all types of credit scenarios.
              You may still be able to obtain credit with negative items on your report, however, lenders may give you a higher interest rate or charge extra fees.
              Typically, negative items may stay on your report for up to 6 years.
              Please click the link below to read further how to support good credit behaviours.
            </p>
            <a [routerLink]="['/edu/content/improving-your-credit-score']">Read More &gt;&gt;</a>
          </div>
        </div>

        <div class="card not-card">
          <div class="card-body">
            <h5 class="card-title">Make Sure There Are No Errors</h5>
            <p>
              The credit bureaux receive millions of data points per day from institutions, lenders, and all kinds of data sources.
              Though rare, there are times that information is incorrectly attributed to the wrong people or information is reported in error to the bureaux due to fraud.
              It is important to make sure that your credit report is free from errors as this may negatively impact your ability to borrow or the rates you may be offered.
            </p>
            <p>
              <a [routerLink]="['/negative-marks/list']">Review Negative Impacts &gt;&gt;</a>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
