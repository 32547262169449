import {inject} from '@angular/core';
import {ResolveFn} from '@angular/router';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {take} from 'rxjs/operators';
import {CreditReport} from 'src/app/shared/models/report/credit-report';

export const dashboardHomeResolver: ResolveFn<CreditReport> = () => {
  return inject(CreditReportService)
    .getNullable()
    .pipe(
      take(1),
    );
}
