import {Component} from '@angular/core';
import {ISummaryStats} from 'src/app/shared/models/report/common/i-summary-stats';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {CreditReport} from 'src/app/shared/models/report/credit-report';
import {ChartData, ChartType} from 'chart.js';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-summary-credit-card-utilization',
  templateUrl: './summary-credit-card-utilization.component.html',
  styleUrls: ['./summary-credit-card-utilization.component.scss'],
})
export class SummaryCreditCardUtilizationComponent {

  summaryStat$: Observable<ISummaryStats>;
  pieChartType: ChartType = 'pie';
  chartData$: Observable<ChartData<'pie'>>;

  constructor(reportService: CreditReportService) {
    this.summaryStat$ = reportService.get()
      .pipe(
        map(rpt => rpt.summary),
      );

    this.chartData$ = reportService.get()
      .pipe(
        map(rpt => this.getData(rpt)),
      )
  }

  private getData(creditReport: CreditReport): ChartData<'pie'> {
    if (!creditReport.summary) {
      return {datasets: [], labels: [], xLabels: [], yLabels: []};
    }

    if (creditReport.summary.creditCardUtilization > 100) {
      return {
        labels: ['Revolving Debt'],
        datasets: [{
          data: [
            creditReport.summary.revolvingDebtAmount,
          ],
        }],
      };
    }

    return {
      labels: ['Utilised', 'Available'],
      datasets: [{
        data: [
          creditReport.summary.revolvingDebtAmount,
          creditReport.summary.creditLimit - creditReport.summary.revolvingDebtAmount,
        ],
      }],
    };
  }
}
