import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {HomeComponent} from './pages/home/home.component';
import {CreditScoreComponent} from './components/credit-score/credit-score.component';
import {CtaGridComponent} from './components/cta-grid/cta-grid.component';
import {CtaItemComponent} from './components/cta-item/cta-item.component';
import {CountUpModule} from 'ngx-countup';
import {SummarySectionNavComponent} from './components/summary-section-nav/summary-section-nav.component';
import {CtaSliderComponent} from './components/cta-slider/cta-slider.component';
import {SummaryCreditScoreComponent} from './pages/summary/summary-credit-score/summary-credit-score.component';
import {SummaryPaymentHistoryComponent} from './pages/summary/summary-payment-history/summary-payment-history.component';
import {SummaryNegativeMarksComponent} from './pages/summary/summary-negative-marks/summary-negative-marks.component';
import {SummaryHardInquiriesComponent} from './pages/summary/summary-hard-inquiries/summary-hard-inquiries.component';
import {
  SummaryCreditCardUtilizationComponent,
} from './pages/summary/summary-credit-card-utilization/summary-credit-card-utilization.component';
import {SummaryDisputesComponent} from './pages/summary/summary-disputes/summary-disputes.component';
import {SummaryBlankComponent} from './pages/summary/summary-blank/summary-blank.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {CoreModule} from '../../core/core.module';
import {DarkWebStatusComponent} from './components/dark-web-status/dark-web-status.component';
import {FastPerksStatusComponent} from './components/fast-perks-status/fast-perks-status.component';
import {CreditScoreProgressBarComponent} from './components/credit-score-progress-bar/credit-score-progress-bar.component';
import {CreditScoreRatingBarComponent} from './components/credit-score-rating-bar/credit-score-rating-bar.component';
import {DarkwebSummaryComponent} from './components/darkweb-summary/darkweb-summary.component';
import {CreditAlertsBoxComponent} from './components/credit-alerts-box/credit-alerts-box.component';
import {CreditConsultationBoxComponent} from './components/credit-consultation-box/credit-consultation-box.component';
import {RiskHeaderComponent} from 'src/app/modules/dark-web/components/risk-header/risk-header.component';
import {DateTimeFormatPipe} from 'src/app/core/pipes/DateTimeFormatPipe';
import {NgChartsModule} from 'ng2-charts';
import {GraphFilterComponent} from 'src/app/shared/components/graph-filter/graph-filter.component';
import {OfferCategoryCtasComponent} from 'src/app/modules/dashboard/components/offer-category-ctas/offer-category-ctas.component';


@NgModule({
  declarations: [
    HomeComponent,
    CreditScoreComponent,
    CreditScoreProgressBarComponent,
    CreditScoreRatingBarComponent,
    CtaGridComponent,
    CtaItemComponent,
    SummarySectionNavComponent,
    CtaSliderComponent,
    SummaryCreditScoreComponent,
    SummaryPaymentHistoryComponent,
    SummaryNegativeMarksComponent,
    SummaryHardInquiriesComponent,
    SummaryCreditCardUtilizationComponent,
    SummaryDisputesComponent,
    SummaryBlankComponent,
    DarkWebStatusComponent,
    FastPerksStatusComponent,
    DarkwebSummaryComponent,
    CreditAlertsBoxComponent,
    CreditConsultationBoxComponent,
  ],
  imports: [
    CommonModule,
    CountUpModule,
    DashboardRoutingModule,
    SharedModule,
    CoreModule,
    RiskHeaderComponent,
    DateTimeFormatPipe,
    NgChartsModule,
    GraphFilterComponent,
    NgOptimizedImage,
    OfferCategoryCtasComponent,
  ],
  exports: [
    HomeComponent,
    CreditScoreComponent,
    CreditScoreProgressBarComponent,
    CreditScoreRatingBarComponent,
  ],
})
export class DashboardModule {
}
