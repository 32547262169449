import {Component} from '@angular/core';
import {ReportHistoryInterface} from 'src/app/shared//models/report-history.interface';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {BehaviorSubject, combineLatest, distinct, Observable} from 'rxjs';
import {ChartConfiguration, ChartType} from 'chart.js';
import 'chartjs-adapter-luxon';
import {filter, map} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-summary-credit-score',
  templateUrl: './summary-credit-score.component.html',
  styleUrls: ['./summary-credit-score.component.scss'],
})
export class SummaryCreditScoreComponent {

  protected data: Array<number> = [];
  protected labels: Array<any> = [];

  hasData$: Observable<boolean>;
  chartData: ChartConfiguration['data'];
  lineChartType: ChartType = 'line';
  monthsSubject = new BehaviorSubject<number>(3);
  private suggestionGraphMax = 1000;
  agencyName$: Observable<string>;

  constructor(private reportService: CreditReportService) {
    this.hasData$ = this.reportService.getHistory().pipe(map(hist => hist.length > 0));
    this.agencyName$ = this.reportService.getSelectedAgencyName();

    // The max of the graph is the maximum score
    reportService
      .get()
      .pipe(takeUntilDestroyed())
      .pipe(filter(rpt => ! (rpt.mvOrThinfile || rpt.reportUnavailable)))
      .subscribe(rpt => this.suggestionGraphMax = rpt.getRating().ScoreMax);

    combineLatest([this.reportService.getHistory(), this.monthsSubject.asObservable()])
      .pipe(
        takeUntilDestroyed(),
        distinct(),
      )
      .subscribe(([reportHistory, months]) => this.refreshStats(months, reportHistory));
  }

  private getData(): ChartConfiguration['data'] {
    return {
      datasets: [
        {
          data: this.data,
          backgroundColor: 'rgba(0,204,255, .2)',
          borderColor: 'rgba(0,204,255, .7)',
          pointBackgroundColor: 'rgba(0,204,255, .2)',
          pointBorderColor: 'rgba(0,204,255, .7)',
          borderWidth: 2,
        },
      ],
      labels: this.labels,
    };
  }

  getOptions(): ChartConfiguration['options'] {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },
      animation: {duration: 1000},
      // hover: {
      //   animationDuration: 0, // duration of animations when hovering an item
      // },
      scales: {
        y: {
          reverse: false,
          min: 100,
          suggestedMax: this.suggestionGraphMax,

          /*ticks: {
            min: 100,
            max: 700,
            step: 50,
          },*/
        },
        x: {
          reverse: false,
          type: 'timeseries',
          time: {
            unit: 'month',
            // unitStepSize: 1,
            displayFormats: {
              millisecond: 'MMM',
              second: 'MMM',
              minute: 'MMM',
              hour: 'MMM',
              day: 'MMM',
              week: 'MMM',
              month: 'MMM',
              quarter: 'MMM',
              year: 'MMM',
            },
          },
          // distribution: 'linear',
        },
      },

    };
  }

  private refreshStats(months: number, history: ReportHistoryInterface[]) {
    this.data = new Array<number>();
    this.labels = new Array<any>();

    if (history.length === 0) {
      return;
    }

    const limit = new Date();
    limit.setMonth(limit.getMonth() - months);

    history.forEach(value => {

      const label = value.created_at.split(' ')[0];
      const dt: Date = new Date(label);
      if (dt >= limit) {
        this.data.push(value.score);
        this.labels.push(dt);
      }

    });

    this.chartData = this.getData();
  }

  monthFilterChange(months: number) {
    this.monthsSubject.next(months);
  }
}
