import {Component} from '@angular/core';
import {CreditReportService} from 'src/app/shared//services/credit-report.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NegativeMarksSummary} from 'src/app/shared/models/report/negative-marks-summary';

@Component({
  selector: 'app-summary-negative-marks',
  templateUrl: './summary-negative-marks.component.html',
  styleUrls: ['./summary-negative-marks.component.scss'],
})
export class SummaryNegativeMarksComponent {

  negativeMarks$: Observable<NegativeMarksSummary>;

  constructor(
    reportService: CreditReportService,
  ) {
    this.negativeMarks$ = reportService
      .get()
      .pipe(
        map(rpt => rpt.getNegativeMarksSummary()),
      );
  }
}
