<div class="summary-section">
  <div class="summary-section-bg">
    <div class="container">
      <h3>Your Credit Score</h3>

      <div class="flex-row">
        <div class="card" *ngIf="this.hasData$ | async">
          <div class="card-body">
            <h5 class="card-title">Score History</h5>
            <div class="spacer"></div>
            <canvas
              baseChart
              [data]="chartData"
              [options]="getOptions()"
              [type]="lineChartType"
            ></canvas>
            <div class="spacer"></div>
            <app-graph-filter (filterChange)="monthFilterChange($event)" [showFiveYear]="true"></app-graph-filter>
            <div class="text-center mt-2">
              <a [routerLink]="['/financial-data/home']">View Details</a>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Why is a Credit Score Important?</h5>
            <div class="spacer"></div>
            <p class="card-text">
              Your credit score is a figure produced by {{ agencyName$ | async }}, one of the credit bureaux in the UK.
              Lenders may potentially check your credit report and score after you have made an application with them.
              The lender may see your credit score and can sometimes make an immediate decision based on the figure.
              Knowing your credit score & more importantly understanding how to improve it can be one of the best financial decisions you can make,
              potentially helping you get access to credit as well as reduce interest rates.
            </p>
              <a [routerLink]="['/edu/content/how-is-score-calculated']">Read More &gt;&gt;</a>
          </div>
        </div>

        <div class="card not-card">
          <div class="card-body">
            <h5 class="card-title">Stay On Top of Your Score</h5>
            <p>
              Reviewing all items on your credit report and verifying that the items are in fact accurate can help you tremendously.
              Should you spot anything that is not yours or may be inaccurate, dispute those items as they may be negatively impacting your credit score.
            </p>
            <p>
              <a [routerLink]="['/negative-marks/list']">Review Score Impacts  &gt;&gt;</a>
            </p>
            <p>
              Reviewing your financial accounts routinely is very important to make sure that you are fully aware of what each item on your credit report is.
              Sometimes you may not recognize an account because the account may be part of an umbrella organisation.
              If you do not recognize an item you should first check the balance of the item to see if you can identify the account.
            </p>
            <p>
              <a [routerLink]="['/financial-data/home']">See Your Financial Data &gt;&gt;</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
