import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {SummaryCreditScoreComponent} from './pages/summary/summary-credit-score/summary-credit-score.component';
import {SummaryPaymentHistoryComponent} from './pages/summary/summary-payment-history/summary-payment-history.component';
import {SummaryDisputesComponent} from './pages/summary/summary-disputes/summary-disputes.component';
import {
  SummaryCreditCardUtilizationComponent,
} from './pages/summary/summary-credit-card-utilization/summary-credit-card-utilization.component';
import {SummaryHardInquiriesComponent} from './pages/summary/summary-hard-inquiries/summary-hard-inquiries.component';
import {SummaryNegativeMarksComponent} from './pages/summary/summary-negative-marks/summary-negative-marks.component';
import {SummaryBlankComponent} from './pages/summary/summary-blank/summary-blank.component';
import {dashboardHomeResolver} from 'src/app/modules/dashboard/resolvers/dashboard-home.resolver';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'prefix',
    redirectTo: 'home',
    data: {scrollToTag: 'dashTop'}
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {path: '', pathMatch: 'prefix', component: SummaryBlankComponent, outlet: 'summary', data: {animation: 'none', scrollToTag: 'summarySectionNav'}},
      {path: 'blank', pathMatch: 'prefix', component: SummaryBlankComponent, outlet: 'summary', data: {animation: 'none', scrollToTag: 'summarySectionNav'}},
      {path: 'summary-credit-score', pathMatch: 'prefix', component: SummaryCreditScoreComponent, outlet: 'summary', data: {animation: 'fader1', scrollToTag: 'summarySectionNav'}},
      {path: 'summary-payment-history', pathMatch: 'prefix', component: SummaryPaymentHistoryComponent, outlet: 'summary', data: {animation: 'fader2', scrollToTag: 'summarySectionNav'}},
      {path: 'summary-negative-marks', pathMatch: 'prefix', component: SummaryNegativeMarksComponent, outlet: 'summary', data: {animation: 'fader3', scrollToTag: 'summarySectionNav'}},
      {path: 'summary-hard-inquiries', pathMatch: 'prefix', component: SummaryHardInquiriesComponent, outlet: 'summary', data: {animation: 'fader4', scrollToTag: 'summarySectionNav'}},
      {path: 'summary-credit-card-utilization', pathMatch: 'prefix', component: SummaryCreditCardUtilizationComponent, outlet: 'summary', data: {animation: 'fader5', scrollToTag: 'summarySectionNav'}},
      {path: 'summary-disputes', pathMatch: 'prefix', component: SummaryDisputesComponent, outlet: 'summary', data: {animation: 'fader6', scrollToTag: 'summarySectionNav'}},
    ],
    resolve: {creditReport: dashboardHomeResolver},
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
}
