import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';

@Component({
  selector: 'app-summary-disputes',
  templateUrl: './summary-disputes.component.html',
  styleUrls: ['./summary-disputes.component.scss'],
})
export class SummaryDisputesComponent {

  disputes$: Observable<Array<any>>;

  private readonly LIST_LIMIT = 5;

  constructor(
    reportService: CreditReportService,
  ) {
    this.disputes$ = reportService
      .getDisputes()
      .pipe(
        map(resp => {
          const disputes = Object.keys(resp).map(key => resp[key]);

          return disputes
            .filter(val => val['meta'].is_open)
            .slice(0, this.LIST_LIMIT);
        }),
      );
  }
}
