import {Component} from '@angular/core';
import {CreditReportService} from 'src/app/shared//services/credit-report.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {SearchInquirySummarized} from 'src/app/shared/models/report/common/search-inquiry-summarized';

@Component({
  selector: 'app-summary-hard-inquiries',
  templateUrl: './summary-hard-inquiries.component.html',
  styleUrls: ['./summary-hard-inquiries.component.scss'],
})
export class SummaryHardInquiriesComponent {

  inquiries$: Observable<SearchInquirySummarized[]>;
  agencyName$: Observable<string>;

  constructor(
    reportService: CreditReportService,
  ) {
    this.agencyName$ = reportService.getSelectedAgencyName();

    this.inquiries$ = reportService.get().pipe(map(rpt => {
      let obj = JSON.parse(JSON.stringify(rpt.inquiries));

      obj = Object.keys(obj)
        .map(key => {
          obj[key].SearchDate = DateTime.fromISO(obj[key].SearchDate);
          return obj[key];
        })
        .sort(SummaryHardInquiriesComponent.sortSearches)
        .slice(0, 3);

      return obj;
    }));
  }

  /**
   * Sort inquiries by date
   */
  private static sortSearches(a, b): number {
    if (a.SearchDate > b.SearchDate) {
      return -1;
    } else if (a.SearchDate < b.SearchDate) {
      return 1;
    }
    return 0;
  }

}
