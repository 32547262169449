import {Component} from '@angular/core';
import {CreditReportService} from 'src/app/shared//services/credit-report.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-summary-payment-history',
  templateUrl: './summary-payment-history.component.html',
  styleUrls: ['./summary-payment-history.component.scss'],
})
export class SummaryPaymentHistoryComponent {

  financialAccounts$: Observable<any>;

  constructor(
    reportService: CreditReportService,
  ) {
    this.financialAccounts$ = reportService
      .get()
      .pipe(
        map(rpt => {
          const obj = rpt.financialAccounts;
          return Object
            .keys(obj)
            .map(key => obj[key])
            .sort(SummaryPaymentHistoryComponent.sortAccounts)
            .slice(0, 3);
        })
      );
  }

  /**
   * Sort missed payments on top, non-"up to date" statuses below it, with healthy
   * accounts last.
   */
  private static sortAccounts(a, b): number {
    if (a.missedPayments > b.missedPayments) {
      return -1;
    } else if (a.missedPayments < b.missedPayments) {
      return 1;
    } else if (a.status !== 'Up to date' && b.status === 'Up to date') {
      return 1;
    } else if (a.status === 'Up to date' && b.status !== 'Up to date') {
      return -1;
    }
    return 0;
  }
}
